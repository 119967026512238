
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import Datatable from '@/components/kt-datatable/kTDatatableLeft.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import moment from 'moment';
import axios from 'axios';
import { ElNotification } from 'element-plus';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'trainee name search',
  components: {
    Form,
    Field,
    Datatable,
    Swal,
    ElNotification,
  },
  data() {
    return {
      moment: '' as any,
      api_url: '',
      traineeList: [],
      trainee: {
        registration_number: '',
        reference_number: '',
        nid: '',
        bcn: '',
        mobile: '',
        father_name: '',
        mother_name: '',
        birth_date: '',
        trainee_name: '',
      },
      tableHeader: [
        {
          name: 'Action',
          key: 'actions',
          sortable: true,
        },
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Photo',
          key: 'photo',
          sortable: true,
        },

        {
          name: 'Trainee Name',
          key: 'name',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Reg No',
          key: 'reg_no',
          sortable: true,
          width: '170px',
        },
        {
          name: 'Ref No',
          key: 'ref_no',
          sortable: true,
          width: '170px',
        },

        {
          name: 'Date of Birth',
          key: 'birth_date',
          sortable: true,
          width: '150px',
        },
        {
          name: 'NID',
          key: 'nid',
          sortable: true,
        },
        {
          name: 'BCN',
          key: 'bcn',
          sortable: true,
          width: '100px',
        },
        {
          name: 'Tranche',
          key: 'tranche',
          sortable: true,
        },
        {
          name: 'Training Partner',
          key: 'training_partner',
          sortable: true,
        },
        {
          name: 'Training Institution',
          key: 'training_institution',
          sortable: true,
        },
        {
          name: 'Course',
          key: 'course',
          sortable: true,
        },
        {
          name: 'Batch',
          key: 'batch',
          sortable: true,
        },

        {
          name: 'Gender',
          key: 'gender',
          sortable: true,
        },
        {
          name: 'Mobile',
          key: 'phone',
          sortable: true,
        },

        {
          name: 'Enrollment',
          key: 'enrollment',
          sortable: true,
        },
        {
          name: 'Start Date',
          key: 'start_date',
          sortable: true,
          width: '150px',
        },
        {
          name: 'End Date',
          key: 'end_date',
          sortable: true,
          width: '150px',
        },
      ],
      user_role: '',
      loading: false,
      load: false,
      showtraineeInfo: false,
      componentKey: 0,
      pdfHeader: [] as any,
      userInfo: {
        employee: {
          entity_organogram: [],
        } as any,
      },
    };
  },
  async created() {
    this.moment = moment;
    this.api_url = this.VUE_APP_API_URL;

    this.userInfo = VueCookieNext.getCookie('_seip_user');
    this.user_role =
      this.userInfo?.employee?.entity_organogram[0]?.entitytyperole?.role_title;
  },
  methods: {
    async getTraineeInfo() {
      if (
        this.trainee.registration_number ||
        this.trainee.reference_number ||
        this.trainee.nid ||
        this.trainee.bcn ||
        this.trainee.trainee_name ||
        this.trainee.father_name ||
        this.trainee.birth_date ||
        this.trainee.mother_name ||
        this.trainee.mobile
      ) {
        this.load = true;
        await ApiService.get(
          'trainee/list?registration_number=' +
            this.trainee.registration_number +
            '&reference_number=' +
            this.trainee.reference_number +
            '&nid=' +
            this.trainee.nid +
            '&bcn=' +
            this.trainee.bcn +
            '&mobile=' +
            this.trainee.mobile +
            '&father_name=' +
            this.trainee.father_name +
            '&mother_name=' +
            this.trainee.mother_name +
            '&birth_date=' +
            this.trainee.birth_date +
            '&trainee_name=' +
            this.trainee.trainee_name
        )
          .then((response) => {
            this.load = false;
            this.traineeList = response.data.data;
            this.showtraineeInfo = true;
            this.componentKey += 1;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Please give input first!',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
  },
});
